import React from "react";
import styled from "styled-components";
import loadable from "@loadable/component"
import { StaticImage } from "gatsby-plugin-image";
import {
  Section,
  SectionWrapper,
  SassyText,
} from "../../components/core/commonExports";
import { useMixpanel } from "../../context/MixpanelContext";
import { getQueryParams } from "../../utils/queryParamUtils";
import { MetaComponent } from "../../components/MetaComponent";
import GLPNavbar from "../../components/campaigns/GLPNavbar";
import { getPageName } from "../../utils/getCurrentPageName";
import { OnDesktop, OnMobile } from "../../components/DesktopMobileComps";
import HeroSection from "../../components/campaigns/HeroSection";
import { HeroBg, QuoteModalPlaceholder, HeroSectionData, HeroSectionContainer, HeroImgContainer, ModalContainer, HeroSectionHeading, HeroSectionCTA } from "../../components/campaigns/SharedComponents";
import { LeadFormProvider } from "../../context/LeadFormContext";
import { useCampaignLPStates } from "../../hooks/useCampaignLPStates";

const LeadForm = loadable(() => import("../../components/LeadForm"));
const Footer = loadable(() => import("../../components/campaigns/Footer"), {
  resolveComponent: (components) => components.Footer,
});
const SelfServe = loadable(() => import("../../components/SelfServe"))

const StepsToProtectBusiness = loadable(() => import(/* webpackPrefetch: true */ "../../components/StepsToProtectBusiness/StepsToProtectBusinessGLPs"))
const ExtraBenefits = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/ExtraBenefits"))
const QuotesFromCompanies = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/glp6/QuotesFromCompanies"))
const ReviewSection = loadable(() => import(/* webpackPrefetch: true */ "../../components/campaigns/google1/ReviewSection"))

const IndexPage = () => {
  const mixpanel = useMixpanel();
  
  const {
    leadformWrapperRef,
    leadformRef,
    scrollToLeadForm,
    hideQuoteCTAs,
    setHideQuoteCTAs,
    screen, 
    setScreen,
    hideHeroSection,
    isSelfServe,
    fetchEstimatedPremium,
    selfServeRef,
    handleCallScheduleClick
  } = useCampaignLPStates()

  // capture and store the query params beforehand
  getQueryParams();

  const pageName = getPageName()

  const handleGetQuoteClick = (e, ctaNumber ="") => {
    mixpanel.track(`Clicked 'Get a Quote'- ${ctaNumber}(${pageName})`);
    scrollToLeadForm()
  };

  const heroSubHeading = (
    <HeroSectionSubHeading>
      Protection from 15+ threats  like fire and burglary, lightning, flood, riots  and many more.
    </HeroSectionSubHeading>
  )

  const heroSectionDefaultComp = (
    <>
      <HeroTextContainer>
        <StyledHeroSectionHeading>
          Insure 10 Lakh worth of stock{" "}
          <SassyText>at just ₹1500!</SassyText>
        </StyledHeroSectionHeading>
        <OnMobile>{heroSubHeading}</OnMobile>
      </HeroTextContainer>
      <OnDesktop>{heroSubHeading}</OnDesktop>
      <HeroImgContainer>
        <StaticImage 
          src="../../assets/images/campaigns/glp3-hero.webp"
          alt="secure 1lakh worth of stock"
          width={484}
          height={282}
          loading="eager"
          placeholder="none"
        />
      </HeroImgContainer>
      {!hideQuoteCTAs && <OnDesktop><HeroSectionCTA label="GET A QUOTE" onClick={(e, ctaNumber=1) => handleGetQuoteClick(e, ctaNumber)} /></OnDesktop>}
    </>
  )

  return (
    <>
      <MetaComponent loadYellowAiScript={false} loadFbPixelScript={false} />
      <GLPNavbar 
        hideQuoteCTA={hideQuoteCTAs || isSelfServe} 
        showCallHelplineCTA={isSelfServe} 
        handleGetQuoteClick={scrollToLeadForm} 
        handleCallScheduleClick={handleCallScheduleClick}
      />
      <LeadFormProvider>
        {isSelfServe ? (
          <SelfServe ref={selfServeRef} fetchEstimatedPremium={fetchEstimatedPremium} fallback={<div style={{height: "100vh", width: "100%"}} />} />
        ) : null}
        <Section background="#ffffff" style={{display: isSelfServe ? "none" : "block", marginTop: '4rem', position: "relative"}}>
          <HeroBg />
          <SectionWrapper mobileStyles={{padding: "24px 0", paddingTop: hideHeroSection ? "0" : "6px"}} desktopStyles={{paddingTop: "40px"}}>
            <HeroSectionData>
              <HeroSectionContainer screen={screen} hideHeroSection={hideHeroSection}>
                <HeroSection 
                  screen={screen} 
                  setScreen={setScreen}
                  heroSectionDefaultComp={heroSectionDefaultComp}
                />
              </HeroSectionContainer>
              <ModalContainer hideHeroSection={hideHeroSection} ref={leadformWrapperRef}>
                <LeadForm 
                  ref={leadformRef}
                  formType="google" 
                  renderAsPopup={false} 
                  setHideQuoteCTAs={setHideQuoteCTAs} 
                  style={{width: 'auto'}} 
                  fallback={<QuoteModalPlaceholder />} 
                  setHeroSectionScreen={setScreen}
                />
              </ModalContainer>
            </HeroSectionData>
          </SectionWrapper>
        </Section>
      </LeadFormProvider>
      {!isSelfServe ? <StepsToProtectBusiness /> : null}
      <ExtraBenefits />
      <QuotesFromCompanies sectionBg="#fff" title={<SassyText>Get Quotes from over 10+ Reputed Insurers</SassyText>} mobileStyles={{}} desktopStyles={{}} />
      <ReviewSection lpName={pageName} handleGetQuoteClick={(e, ctaNumber=2) => handleGetQuoteClick(e, ctaNumber)} />
      <Footer style={{background: "#fff"}} />
    </>
  );
};

const HeroTextContainer = styled.div`
  @media screen and (max-width: 450px) {
    max-width: 175px;
  }
`

const StyledHeroSectionHeading = styled(HeroSectionHeading)`
  @media (max-width: 768px) {
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const HeroSectionSubHeading = styled.h4` 
  font-size: 16px;
  color: #4C4C4C;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
  }
`

export default IndexPage;
